<template>
  <div>
    <label>Canal</label>
    <b-form-select     
      :disabled="disabled"
      :options="selectOptions"     
      :value="value" 
      @change="change_value($event)"
    ></b-form-select>    
  </div>
</template>

<script>
export default {
  props: ["value", "disabled"],
  computed: {
    globalState(){
      return this.$store.state.global
    },
    selectOptions() {
      const first = {
        value: "",
        text: "Selecione o canal",
        disabled: true,
      };
      let channels = _.map(
        this.globalState.channels,
        (channel) => {
          return {text:channel.channel, value:channel.id};
        }
      );
      channels.unshift(first)
      return channels
    },
  },
  methods:{
    change_value(v){
        this.$emit('input', v)
    }
  }
};
</script>

<style>
</style>